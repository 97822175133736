@charset "UTF-8";
@import "../../../setting.scss";
@import "../../../mixin.scss";
// @import "../../../common.scss";
@import "../../../form.scss";

#login {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .wrapper {
      /*width: 430px;*/
      text-align: center;
      h1 {
        margin-bottom: 56px;
      }
      .alert {
        margin-bottom: 20px;
      }
      > button {
        width: 100%;
        max-width: 420px;
        height: 50px;
        font-size: 16px;
        margin-bottom: 20px;
      }
      a {
        text-decoration: underline;
        color: #2B72B3;
        font-size: 18px; 
      }
      .input_wrap {
        position: relative;
        max-width: 420px;
        margin: 0 auto;
        input {
          width: 100% !important;
          max-width: 420px;
          height: 50px;
          @include radius(2px);
          @include border-default;
          padding-left: 15px;
          margin-bottom: 40px;
          /*&[type="text"] ,
          &[type="password"] {
            padding-right: 50px;
          }*/
        }
        /*> img {
          position: absolute;
          top: 8px;
          left: 6px;
        } button {
          position: absolute;
          top: 6px;
          right: 6px;
        }*/
      }
    }
    &.company {
      .wrapper {
        h1 {
          margin-bottom: 40px;
        }
        .input_wrap {
          input {
            margin-bottom: 30px;
          }
        }
        .checkbox_wrap {
          width: 100% !important;
          max-width: 420px;
          margin: auto;
          margin-bottom: 60px;

        }
      }
    }
  }

