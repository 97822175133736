.checkbox {
  input[type=checkbox] {
    +label {
      &.bold {
        font-weight: bold !important;
      }
    }
  }
}

.counter {
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  font-size: 14px;
}