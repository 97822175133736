@charset "UTF-8";
@import "../../../setting.scss";
@import "../../../mixin.scss";

.spinner {
  width: 36px;
  height: 36px;
  border: 4px #ddd solid;
  border-top: 4px $site_color solid;
  border-radius: 50%;
  animation: sp-anime 1.0s infinite linear;
}

@keyframes sp-anime {
  100% {
    transform: rotate(360deg);
  }
}