@use '../../../mixin' as mx;
@use '../../../setting' as st;

.information_detail {
  .inner+.inner {
    overflow: auto;
  }

  section {
    padding: 0 clamp(10px, 4.6875vw, 60px);

    // max-width: 870px;
    >header {
      border-bottom: none !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    >.edit_wrap {
      max-width: initial !important;
      width: 100% !important;

      .item_head {
        // width: clamp()
      }
    }
  }

  &__top {
    &__actions {
      @include mx.flex_box;
      gap: 0 10px;

      button {
        // min-width: max-content;
        padding: 0.5em;
      }
    }
  }

  &__bottom {
    header {
      h2 {
        flex: none !important;
      }
    }

    .table_box {
      border: 1px solid #707070;
      padding: 0.3125rem;
      pointer-events: none;
    }
  }
}

.detail_box {
  &__inner {
    transition: 0.5s;
    margin-bottom: 0 !important;
  }

  .second_part {
    transition: 0.5s;
    overflow: hidden;
  }

  &__toggle {
    height: 2rem;
    @include mx.flex_box;
    @include mx.flex_all_center;
    border-top: 1px solid rgba(0, 0, 0, 0.03);
    margin-top: 6px;
    padding-top: 5px;
    cursor: pointer;

    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
      user-select: none;
    }

    &::before {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #75174A;
    }

    &::after {
      $shape_height: 0.4rem;
      width: 0;
      height: 0;
      border-top: calc(#{$shape_height} / 4 * 3) solid transparent;
      border-right: calc(#{$shape_height} / 4 * 3) solid transparent;
      border-bottom: $shape_height solid #fff;
      border-left: calc(#{$shape_height} / 4 * 3) solid transparent;
      transform: translateY(-30%);
    }

    &.close {
      &::before {
        transform: rotate(180deg);
      }

      &::after {
        transform: translateY(30%) rotate(180deg);
      }
    }
  }
}