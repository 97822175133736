@use '../../../setting' as st;

.rich_text {
  width: 100%;
  height: 100%;
  overflow: auto;

  &.edit {
    border: 1px solid st.$form_border_color;

    .rich_text__inner {
      padding-left: 10px;
    }
  }

  &__wrap {
    width: 100%;
  }

  &__inner {
    min-width: 100%;
    // width: max-content;
    height: 100%;

    .codex-editor {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      &__loader {
        // height: 100%
      }

      &__redactor {
        padding-bottom: 0 !important;
        margin-right: 4em;

        .ce-block {
          &__content {
            max-width: 100%;
          }
        }
      }

      .ce-toolbar {
        &__content {
          max-width: initial;
        }

        &__actions {
          right: 0;
        }
      }

      &--narrow {
        .ce-toolbox {
          .ce-popover {
            right: 10px;
          }
        }

        .ce-settings {
          left: initial;
          right: 10px;
        }
      }
    }
  }
}